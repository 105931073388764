<template>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<b-card>
					<b-row>
						<b-col sm="12">
						<!-- Parameter -->
						<h6><span class="align-middle ml-50 text-dark">NIMOS SYNCHRONIZATION HISTORY</span></h6>
						<b-card no-body class="border mt-2">
							<a-spin :spinning="LOADING_TABLE" >
								<a-table :columns="COLUMN_HISTORY" :data-source="DATA_HISTORY">

									<span slot="description-column" slot-scope="text, record">
										<!-- <pre v-html="nimos_sync_description">
										</pre> -->
										{{record.modify_description}}
									</span>

									<span slot="action-column" slot-scope="text, record">  
										<a-tooltip placement="left"  >
											<template slot="title">
											<span>DOWNLOAD ERROR LOG</span>
											</template>
											<a-button-group>
											<a-button type="primary" icon="cloud-download"  @click="onDownloadTXT(record)"/>
											</a-button-group>
										</a-tooltip>
										<!-- <span v-if="record.ingest_flag != 3">-</span> -->
									</span> 

								</a-table>
							</a-spin>
						</b-card>
						</b-col>

					</b-row>
				</b-card>
			</div>
		</div>

	</div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTable, BFormTextarea } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import Metadata from "@/api/metadata";
export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BTable,
		BFormTextarea,
	},
	data: function() {
		return {
			LOADING_TABLE : false,
			COLUMN_HISTORY : [
				{
					title: 'SYNCH DATE',
					dataIndex: 'nimos_sync_date',
					key: 'nimos_sync_date',
					scopedSlots: { customRender: "synch_date-column" },
					width: 200
				},
				{
					title: 'TOTAL ROWS',
					dataIndex: 'nimos_total_rows',
					key: 'nimos_total_rows',
					scopedSlots: { customRender: "total_rows-column" },
					width: 200,
					align: 'center'
				},
				{
					title: 'DESCRIPTION',
					dataIndex: 'nimos_description',
					key: 'nimos_description',
					scopedSlots: { customRender: "description-column" },
				},
				{
					title: 'ACTION',
					dataIndex: 'nimos_action',
					key: 'nimos_action',
					scopedSlots: { customRender: "action-column" },
					align: 'center'
				}
			],
			DATA_HISTORY : [],
		};
  	},

	mounted() {
		this.getListNimosSynchronization()
	},

	methods: {

		getListNimosSynchronization() {
			this.LOADING_TABLE = true;
			try {
				Metadata.listNimosSynchronization()
					.then((response) => {
						this.showLoading = false;
						let data = response.data
						this.filterDataLog(data)
						this.LOADING_TABLE = false;
					})
					.catch((error) => {
						this.LOADING_TABLE = false;
						console.log("Error : ", error);
					});
			} catch (error) {
				this.LOADING_TABLE = false;
				console.log("error: ", error);
			}
		},

		filterDataLog(data) {
			// console.log('data', data)
			data.sort(function(a, b){
				return new Date(b.nimos_sync_date) - new Date(a.nimos_sync_date)
			});

			for (let log of data) {
				let desc = log.nimos_description
				desc = desc.replace(/(\r\n|\n|\r)/gm, "");
				const count = 100;  
				const result = desc.slice(0, count) + (desc.length > count ? "..." : "");  

				log['modify_description'] = result
				let date = log.nimos_sync_date.split('T')
				log['nimos_sync_date'] = date[0] + ' ' + date[1]
			}

			this.DATA_HISTORY = data

			// console.log('this.DATA_HISTORY', this.DATA_HISTORY)
		},

		onDownloadTXT(RECORD){
			var blob = new Blob([ RECORD.nimos_description ], { "type" : "text/plain" });
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'log-sync-nimos.txt';
			link.click();
		},
		
	}

	

};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
